function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
define('TescoMobile_Algolia/js/hooks',['jquery', 'underscore', 'algoliaBundle', 'TescoMobile_DataLayer/js/algolia-hits-preparator', './model/autocomplete/transformHit', 'lucidHtml', './template/autocomplete/wrapper', 'TescoMobile_Algolia/js/model/analytics-tags', './model/instantsearch/position-breakout-tiles', 'TescoMobile_Algolia/js/isNew', 'TescoMobile_Algolia/js/filters', 'matchMedia', 'mage/cookies', 'algoliaCommon'], function ($, _, algoliaBundle, DataLayerAlgoliaHitsPreparator, autocompleteTransformHit, lucidHtml, autocompleteWrapper, analyticsTags, positionBreakoutTiles, isNew, algoliaFilters, mediaCheck) {
  let filtersInitialised = false;
  function debounce(func) {
    var _this = this;
    let timeout = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 300;
    let timer;
    return function () {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(_this, args);
      }, timeout);
    };
  }
  const snippetResultContent = hit => {
    if (hit._snippetResult && hit._snippetResult.content && hit._snippetResult.content.value.length > 0) {
      hit.content = hit._snippetResult.content.value;
      if (hit.content.charAt(0).toUpperCase() !== hit.content.charAt(0)) {
        hit.content = '... ' + hit.content;
      }
      if ($.inArray(hit.content.charAt(hit.content.length - 1), ['.', '!', '?'])) {
        hit.content = hit.content + ' ...';
      }
      return hit.content;
    }
  };
  const itemReplace = new URL(window.location.href).searchParams.get("item_replace");
  const parametersObject = [];
  const parametersArray = ["utm_source", "utm_medium", "utm_campaign", "utm_content", "utm_term", "at_preview_token", "at_preview_index", "at_preview_listed_activities_only", "mboxDisable"];
  parametersArray.forEach(element => {
    parametersObject[element] = new URL(window.location.href).searchParams.get(element);
  });
  const hitTransformer = () => {
    let isList = algoliaConfig.isCategoryPage && !!algoliaConfig.category.display_as_list;
    var transformHit = window.transformHit;
    window.transformHit = function (hit, price_key, helper, payload) {
      var _hit$tm_contract_type, _hit$tm_contract_type2, _hit$tm_contract_type3, _hit$tm_contract_type4, _hit$colors, _hit$tm_contract_type5, _hit$tm_contract_type6, _hit$tm_contract_type7, _hitUpdated$promotion, _hitUpdated$promotion2, _window$algolia;
      window.algolia.hitCounts = {};
      var hitUpdated = transformHit(hit, price_key, helper);
      const addToCompareAction = algoliaConfig.baseUrl + '/catalog/product_compare/add';
      hitUpdated.addToCompare = {
        'dataPost': JSON.stringify({
          'action': addToCompareAction,
          'data': {
            'product': hitUpdated.objectID,
            'uenc': AlgoliaBase64.mageEncode(window.location.href)
          }
        })
      };
      if (hit.tm_tariff_tenure) {
        const tenure = hit.tm_tariff_tenure[0];
        hit.contract_duration = tenure > 1 ? tenure + ' months contract' : tenure + ' month contract';
      }
      hitUpdated.__is_simo = (hit === null || hit === void 0 || (_hit$tm_contract_type = hit.tm_contract_type) === null || _hit$tm_contract_type === void 0 ? void 0 : _hit$tm_contract_type.indexOf('SIMO')) !== -1;
      hitUpdated.__is_bau = (hit === null || hit === void 0 || (_hit$tm_contract_type2 = hit.tm_contract_type) === null || _hit$tm_contract_type2 === void 0 ? void 0 : _hit$tm_contract_type2.indexOf('BAU')) !== -1;
      hitUpdated.__is_aus = (hit === null || hit === void 0 || (_hit$tm_contract_type3 = hit.tm_contract_type) === null || _hit$tm_contract_type3 === void 0 ? void 0 : _hit$tm_contract_type3.indexOf('AUSTANDARD')) !== -1;
      hitUpdated.__is_payg_essentials = (hit === null || hit === void 0 || (_hit$tm_contract_type4 = hit.tm_contract_type) === null || _hit$tm_contract_type4 === void 0 ? void 0 : _hit$tm_contract_type4.indexOf('PAYG_ESSENTIALS')) !== -1;
      hitUpdated.__tm_contract_type = (hit === null || hit === void 0 ? void 0 : hit.tm_contract_type) || '';
      hitUpdated.__is_new = isNew(hit);
      var userData = [];
      if (payload) {
        userData = payload === null || payload === void 0 ? void 0 : payload.userData;
      } else if (helper) {
        var _helper$lastResults;
        userData = helper === null || helper === void 0 || (_helper$lastResults = helper.lastResults) === null || _helper$lastResults === void 0 ? void 0 : _helper$lastResults.userData;
      }
      var renderTypes = [];
      if (userData) {
        renderTypes = userData.map(item => item.render_as);
      }
      if (typeof window.algolia.earlyUpgrade !== "undefined") {
        if (!hitUpdated.__is_simo) {
          hitUpdated.__early_upgrade = hitUpdated.__is_bau ? window.algolia.earlyUpgrade.bau : window.algolia.earlyUpgrade.aus;
        }
        if (hitUpdated.__is_simo) {
          hitUpdated.__early_upgrade = window.algolia.earlyUpgrade.simo;
        }
      }
      hit.tm_points_payg = Math.floor(hit.price.GBP.payg * algoliaConfig.reward_rate);
      if (isNaN(hit.tm_points_payg)) hit.tm_points_payg = 0;
      hit.tm_points_sim_free = Math.floor(hit.price.GBP.sim_free * algoliaConfig.reward_rate);
      if (isNaN(hit.tm_points_sim_free)) hit.tm_points_sim_free = 0;
      hit.tm_payg_price = hit.price.GBP.formated_payg;
      hit.tm_sim_free_price = hit.price.GBP.formated_sim_free;
      if (hit.amtoolkit_canonical) {
        hit.url = hit.amtoolkit_canonical;
      }
      if (typeof hit.tm_allowance_data_unlimited !== "undefined") {
        hitUpdated.__tm_allowance_data_formated = 'Unlimited data';
      } else {
        if (hit.tm_allowance_data) {
          hitUpdated.__tm_allowance_data_formated = hit.tm_allowance_data < 1024 ? hit.tm_allowance_data + 'MB' : Math.round(hit.tm_allowance_data / 1024) + 'GB';
        }
      }
      if (typeof hit.tm_allowance_minutes_unlimited !== "undefined" && typeof hit.tm_allowance_sms_unlimited !== "undefined") {
        hitUpdated.__tm_minutes_sms_label = 'Unlimited minutes & texts';
      } else {
        let label;
        if (hit.tm_allowance_minutes) {
          label = hit.tm_allowance_minutes + ' minutes';
        }
        if (hit.tm_allowance_sms) {
          label += ' & ' + hit.tm_allowance_sms + ' texts';
        }
        hitUpdated.__tm_minutes_sms_label = label;
      }
      if (typeof hit.tm_allowance_minutes_unlimited !== "undefined" && typeof hit.tm_allowance_sms_unlimited === "undefined") {
        let label = hit.tm_allowance_sms + ' texts';
        hitUpdated.__tm_minutes_sms_label = 'Unlimited minutes & ' + label;
      }
      if (typeof hit.tm_allowance_minutes_unlimited === "undefined" && typeof hit.tm_allowance_sms_unlimited !== "undefined") {
        let label = hit.tm_allowance_minutes + ' minutes ';
        hitUpdated.__tm_minutes_sms_label = label + '& texts';
      }
      if (typeof hit.tm_network_service !== "undefined" && Array.isArray(hit.tm_network_service)) {
        hitUpdated.__tm_network_service_label = 'Works on ' + hit.tm_network_service.join(' & ');
      }
      if ((hit === null || hit === void 0 || (_hit$colors = hit.colors) === null || _hit$colors === void 0 ? void 0 : _hit$colors.length) >= 4) {
        hitUpdated.__showMoreColor = true;
      }
      if (algoliaConfig.isCategoryPage && algoliaConfig.category.use_canonical_product_link && hit.amtoolkit_canonical) {
        hit.url = hit.amtoolkit_canonical;
      } else {
        if (hit.url_rewrites) {
          let urlRewrites = hit.url_rewrites;
          if (algoliaConfig.isCategoryPage) {
            urlRewrites = urlRewrites.filter(urlRewrite => urlRewrite.indexOf(algoliaConfig.category.url_path) === 0);
          }
          if (urlRewrites.length > 0 && algoliaConfig.isCategoryPage) {
            var originalUrl = '';
            if (algoliaConfig.isCategoryPage) {
              originalUrl = urlRewrites.reduce((a, b) => {
                return a.length < b.length ? a : b;
              });
            } else {
              originalUrl = urlRewrites.reduce((a, b) => {
                return a.length > b.length ? a : b;
              });
            }
            hit.url = `${document.location.origin}/${originalUrl}`;
          }
        }
      }
      if (hit.__queryID) {
        hit.urlForInsights = hit.url;
        if (algoliaConfig.ccAnalytics.enabled && algoliaConfig.ccAnalytics.conversionAnalyticsMode !== 'disabled') {
          var paramObject = {
            queryID: hit.__queryID,
            objectID: hit.objectID,
            indexName: hit.__indexName
          };
          if (!algoliaConfig.isCategoryPage) {
            paramObject['isSearchPage'] = 1;
          }
          var insightsDataUrlString = $.param(paramObject);
          hit.urlForInsights += hit.url.indexOf('?') > -1 ? insightsDataUrlString : '?' + insightsDataUrlString;
        }
      }
      if (hit.manufacturer != undefined) hitUpdated.__manufacturer = Array.isArray(hit.manufacturer) ? hit.manufacturer[0] : hit.manufacturer;
      hit._highlightResult.name.value = hit.name.indexOf(hit.manufacturer) === 0 ? hit.name.replace(hit.manufacturer, '').trim() : hitUpdated.__is_simo || hitUpdated.__is_payg_essentials ? hitUpdated.__tm_allowance_data_formated : hit.name;
      hitUpdated.__paym = true;
      hitUpdated.__payg = false;
      hitUpdated.__simo = false;
      hitUpdated.__simfree = false;
      hitUpdated.__fusion = false;
      hitUpdated.__payg_essentials = false;
      if (algoliaConfig.isCategoryPage && algoliaConfig.category.product_type === 'PAYG' || hit.displayType === 'PAYG' || renderTypes.length > 0 && hit.tm_contract_type.includes('PAYG') && renderTypes.includes('payg')) {
        var _hitUpdated$price;
        hitUpdated.__paym = false;
        hitUpdated.__payg = true;
        hitUpdated.__simo = false;
        hitUpdated.__simfree = false;
        hitUpdated.__fusion = false;
        hitUpdated.__payg_essentials = false;
        if ((_hitUpdated$price = hitUpdated.price) !== null && _hitUpdated$price !== void 0 && (_hitUpdated$price = _hitUpdated$price.GBP) !== null && _hitUpdated$price !== void 0 && _hitUpdated$price.formated_payg) {
          hitUpdated.price.GBP.default_formated = hitUpdated.price.GBP.formated_payg;
        }
      }
      if (algoliaConfig.isCategoryPage && algoliaConfig.category.product_type === 'SIM-free' || hit.displayType === 'SIM-free') {
        hitUpdated.__paym = false;
        hitUpdated.__payg = false;
        hitUpdated.__simo = false;
        hitUpdated.__simfree = true;
        hitUpdated.__fusion = false;
        hitUpdated.__payg_essentials = false;
      }
      if (algoliaConfig.isCategoryPage && algoliaConfig.category.product_type === 'SIMO' || hit.displayType === 'SIMO') {
        hitUpdated.__paym = false;
        hitUpdated.__payg = false;
        hitUpdated.__simo = true;
        hitUpdated.__simfree = false;
        hitUpdated.__fusion = false;
        hitUpdated.__payg_essentials = false;
        if (hit.__position == 1) {
          hitUpdated.__recommendation = false;
        }
      }
      if (algoliaConfig.isCategoryPage && algoliaConfig.category.product_type === 'FUSION' || hit.displayType === 'FUSION') {
        hitUpdated.__paym = false;
        hitUpdated.__payg = false;
        hitUpdated.__simo = false;
        hitUpdated.__simfree = false;
        hitUpdated.__fusion = true;
        hitUpdated.__payg_essentials = false;
        if (hit.__position == 1) {
          hitUpdated.__recommendation = false;
        }
      }
      if (algoliaConfig.isCategoryPage && algoliaConfig.category.product_type === 'PAYG_ESSENTIALS' || hit.displayType === 'PAYG_ESSENTIALS') {
        hitUpdated.__paym = false;
        hitUpdated.__payg = false;
        hitUpdated.__simo = false;
        hitUpdated.__simfree = false;
        hitUpdated.__fusion = false;
        hitUpdated.__payg_essentials = true;
        if (hit.__position == 1) {
          hitUpdated.__recommendation = false;
        }
      }
      if (algoliaConfig.isSearchPage && (hit === null || hit === void 0 || (_hit$tm_contract_type5 = hit.tm_contract_type) === null || _hit$tm_contract_type5 === void 0 ? void 0 : _hit$tm_contract_type5.indexOf('SIMO')) !== -1) {
        hitUpdated.__paym = false;
        hitUpdated.__payg = false;
        hitUpdated.__simo = true;
        hitUpdated.__simfree = false;
        hitUpdated.__fusion = false;
        hitUpdated.__payg_essentials = false;
      }
      if (algoliaConfig.isSearchPage && (hit === null || hit === void 0 || (_hit$tm_contract_type6 = hit.tm_contract_type) === null || _hit$tm_contract_type6 === void 0 ? void 0 : _hit$tm_contract_type6.indexOf('SIM-free')) !== -1) {
        hitUpdated.__paym = false;
        hitUpdated.__payg = false;
        hitUpdated.__simo = false;
        hitUpdated.__fusion = false;
        hitUpdated.__simfree = true;
        hitUpdated.__payg_essentials = false;
      }
      if (algoliaConfig.isSearchPage && (hit === null || hit === void 0 || (_hit$tm_contract_type7 = hit.tm_contract_type) === null || _hit$tm_contract_type7 === void 0 ? void 0 : _hit$tm_contract_type7.indexOf('PAYG_ESSENTIALS')) !== -1) {
        hitUpdated.__paym = false;
        hitUpdated.__payg = false;
        hitUpdated.__simo = false;
        hitUpdated.__fusion = false;
        hitUpdated.__simfree = false;
        hitUpdated.__payg_essentials = true;
      }
      if ((hitUpdated === null || hitUpdated === void 0 || (_hitUpdated$promotion = hitUpdated.promotions) === null || _hitUpdated$promotion === void 0 ? void 0 : _hitUpdated$promotion.length) > 0) {
        if (hitUpdated.__paym === true) {
          hitUpdated.promotions = hitUpdated.promotions.filter(item => parseInt(item.paym) === 1);
        }
        if (hitUpdated.__payg === true) {
          hitUpdated.promotions = hitUpdated.promotions.filter(item => parseInt(item.payg) === 1);
        }
        if (hitUpdated.__simo === true) {
          hitUpdated.promotions = hitUpdated.promotions.filter(item => parseInt(item.simo) === 1);
        }
        if (hitUpdated.__simfree === true) {
          hitUpdated.promotions = hitUpdated.promotions.filter(item => parseInt(item.sim_free) === 1);
        }
      }
      hitUpdated.__promo_template_single = (hitUpdated === null || hitUpdated === void 0 || (_hitUpdated$promotion2 = hitUpdated.promotions) === null || _hitUpdated$promotion2 === void 0 ? void 0 : _hitUpdated$promotion2.length) === 1;
      if (!algoliaConfig.isCategoryPage) {
        hit.__isSearchPage = true;
      }
      if (helper != undefined && !!((_window$algolia = window.algolia) !== null && _window$algolia !== void 0 && _window$algolia.hitCounts)) {
        if (helper !== null && helper !== void 0 && helper.lastResults) {
          window.algolia.hitCounts.products = helper.lastResults.nbHits;
        } else {
          window.algolia.hitCounts.products = 0;
        }
        if (hit.__position == helper.lastResults.page * helper.lastResults.hitsPerPage + helper.lastResults.hitsPerPage || hit.__position == helper.lastResults.nbHits) {
          setTimeout(function () {
            if (!isList) processEqualHeights();
            facetColorSwatches();
          }, 100);
        }
      }
      DataLayerAlgoliaHitsPreparator.prepareHitUpdatedData(hitUpdated, helper);
      hitUpdated.__tm_is_scd = (hit === null || hit === void 0 ? void 0 : hit.tm_is_scd) === "Yes";
      hitUpdated.__tm_has_product_label = hitUpdated.__tm_is_scd || (hitUpdated === null || hitUpdated === void 0 ? void 0 : hitUpdated.__early_upgrade) || (hit === null || hit === void 0 ? void 0 : hit.tm_product_label);
      if (itemReplace) {
        let action = algoliaConfig.instant.addToCartParams.action + 'product/' + hit.objectID + '/';
        action = action.replace('cart/add', 'simo/replace');
        action = action + 'item/' + itemReplace + '/';
        const correctFKey = $.mage.cookies.get('form_key');
        if (correctFKey != "" && algoliaConfig.instant.addToCartParams.formKey != correctFKey) {
          algoliaConfig.instant.addToCartParams.formKey = correctFKey;
        }
        hit.addToCart = {
          'action': action,
          'uenc': AlgoliaBase64.mageEncode(action),
          'formKey': algoliaConfig.instant.addToCartParams.formKey
        };
      }
      return hitUpdated;
    };
  };
  algolia.addToCartFunction = (form, e) => {
    if (document.location.pathname.indexOf('/checkout') === 0) {
      return true;
    }
    e.preventDefault();
    const jqForm = $(form).catalogAddToCart({
      bindSubmit: false
    });
    jqForm.catalogAddToCart('ajaxSubmit', jqForm);
    return false;
  };
  algolia.customSearch = function (holderElement, categoryId) {
    let displayType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
    let cssClasses = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
    let limit = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
    let autoHeight = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : false;
    const searchClient = algoliaBundle.algoliasearch(algoliaConfig.applicationId, algoliaConfig.apiKey);
    const indexName = algoliaConfig.indexName + '_products';
    const search = algoliaBundle.instantsearch({
      indexName: indexName,
      searchClient: searchClient,
      searchFunction: function (helper) {
        helper.state.facets = ['categoryIds'];
        helper.state.facetFilters = [`categoryIds:${categoryId}`];
        if (limit) {
          helper.state.hitsPerPage = limit;
        }
        helper.search();
      }
    });
    holderElement.innerHTML = '';
    for (const cssClass of cssClasses) {
      holderElement.classList.add(cssClass);
    }
    const widgetConfigurations = {
      container: holderElement,
      transformItems: items => items.map(item => {
        if (displayType) {
          item.displayType = displayType;
        }
        item = transformHit(item, algoliaConfig.priceKey, search.helper);
        if (displayType && displayType === 'PAYM') {
          item.__paym = true;
          item.__payg = false;
          item.__simo = false;
          item.__simfree = false;
          item.__fusion = false;
          item.__payg_essentials = false;
        }
        return item;
      }),
      templates: {
        item: document.getElementById('instant-hit-template').innerHTML
      }
    };
    if (limit) {
      search.addWidgets([algoliaBundle.instantsearch.widgets.hits(widgetConfigurations)]);
    } else {
      search.addWidgets([algoliaBundle.instantsearch.widgets.infiniteHits(widgetConfigurations)]);
    }
    hitTransformer();
    search.start();
    search.on('render', () => {
      if (autoHeight) {
        processEqualHeights();
      }
      $(search).trigger('complete');
    });
    return search;
  };
  algolia.searchAlternativeDeals = function () {
    let resultsHolder = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    let displayType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    let cssClasses = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
    if (!algoliaConfig.alternativeDealsCategoryId) {
      console.warn('Alternative deals not setup. Please configure in Magento Admin.');
    }
    if (!resultsHolder) {
      resultsHolder = '#instant-search-results-container';
    }
    if (!document.querySelector(resultsHolder)) {
      console.warn('Alternative deals issue:', 'Container not found');
      return;
    }
    const holderElement = document.querySelector(resultsHolder);
    algolia.customSearch(holderElement, algoliaConfig.alternativeDealsCategoryId, displayType, cssClasses);
  };
  const triggerInsightsTrackClickEvent = function (eventName, element) {
    let preventDuplicate = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
    let algoliaInsights = window.algoliaInsights;
    let $this = $(element);
    if (preventDuplicate && $this.data('clicked-tm')) {
      return;
    }
    let eventData = algoliaInsights.buildEventData(eventName, $this.data('objectid'), $this.data('index') || $this.data('indexname'), $this.data('position'), $this.data('queryid'));
    algoliaInsights.trackClick(eventData);
    if (preventDuplicate) {
      $this.attr('data-clicked-tm', true);
    }
  };
  algolia.registerHook('afterAutocompleteStart', function (algoliaAutocompleteInstance) {
    const submitIcon = $('.aa-SubmitIcon');
    if (submitIcon) {
      submitIcon.replaceWith('<span class="icon icon-search"></span>');
    }
    $(document).on('click', '.aa-List .aa-Item a.product-item', function () {
      triggerInsightsTrackClickEvent('Autocomplete: Product clicked', this);
    });
    $(document).on('click', '.aa-List .aa-Item a.algoliasearch-autocomplete-hit', function () {
      triggerInsightsTrackClickEvent('Autocomplete: Page clicked', this);
    });
    $(document).on('click', '.aa-List .aa-Item a.hit-name', function () {
      const parent = this.closest('.algoliasearch-autocomplete-hit');
      triggerInsightsTrackClickEvent('Autocomplete: Phone guide clicked', parent);
    });
    registerInsightsPDPEvents();
    return algoliaAutocompleteInstance;
  });
  algolia.registerHook('beforeWidgetInitialization', function (allWidgetConfiguration, algoliaBundle) {
    delete allWidgetConfiguration.currentRefinements;
    delete allWidgetConfiguration.searchBox;
    $.map(allWidgetConfiguration.refinementList, function (object, i) {
      if (object.attribute === 'manufacturer') {
        object.limit = 4;
        object.showMore = true;
      }
      if (object.attribute === 'tm_allowance_data') {
        object.showMore = true;
        object.limit = 4;
        object.searchable = false;
        object.transformItems = function (items) {
          return items.map(item => {
            let itemValue;
            itemValue = parseInt(item.value) >= algoliaConfig.tmUnlimitedDataThreshold ? 'Unlimited' : item.label < 1024 ? item.label + 'MB' : Math.round(item.label / 1024) + 'GB';
            return _objectSpread(_objectSpread({}, item), {}, {
              label: itemValue
            });
          });
        };
        object.templates.item = `
                <label class="{{cssClasses.label}} {{#isRefined}}checked{{/isRefined}}">
                    {{label}}
                    <span class="{{cssClasses.count}}">{{count}}</span>
                    {{#isRefined}}<span class="icon icon-tick"></span>{{/isRefined}}
                </label>
                `;
      }
      if (object.attribute === 'color') {
        object.attribute = 'color';
        object.showMore = true;
        object.limit = 4;
        object.templates.item = `
                    <label class="{{cssClasses.label}} {{#isRefined}}checked{{/isRefined}}">
                    <span class="swatch" style="background-color: {{hex_code}}"></span>
                    <span class="name">{{value}}</span>
                    <span class="{{cssClasses.count}}">{{count}}</span>
                    {{#isRefined}}<span class="icon icon-tick"></span>{{/isRefined}}
                    </label>
                `;
      }
    });
    if (algoliaConfig.isSearchPage && !algoliaConfig.isCategoryPage) {
      allWidgetConfiguration.stats = {
        container: '#tab-product-count',
        templates: {
          text: `
                          {{^areHitsSorted}}
                            {{#hasNoResults}}(0){{/hasNoResults}}
                            {{#hasOneResult}}({{#helpers.formatNumber}}{{nbHits}}{{/helpers.formatNumber}}){{/hasOneResult}}
                            {{#hasManyResults}}({{#helpers.formatNumber}}{{nbHits}}{{/helpers.formatNumber}}){{/hasManyResults}}
                          {{/areHitsSorted}}
                        `
        }
      };
    }
    allWidgetConfiguration.configure['analyticsTags'] = analyticsTags.getTags('instantsearch');
    return allWidgetConfiguration;
  });
  algolia.registerHook('afterInstantsearchStart', function (search, algoliaBundle) {
    const wrapper = $('.algolia-instant-results-wrapper');
    const tabWrapper = wrapper === null || wrapper === void 0 ? void 0 : wrapper.find('.tabs-inner');
    if (!filtersInitialised) {
      filtersInitialised = true;
      algoliaFilters();
    }
    mediaCheck({
      media: '(min-width: 1009px)',
      entry: function () {
        $('#skeleton-loader--wrapper').css('position', 'absolute');
        $('#skeleton-button').css('visibility', 'hidden');
      }
    });
    function toggleTabs(selectedTab, container, tabWrapper) {
      var _container$find;
      const tabs = tabWrapper === null || tabWrapper === void 0 ? void 0 : tabWrapper.find('.tab');
      if (!selectedTab || tabs.length === 0) {
        return;
      }
      $('.inpage-aa-dataset, .algolia-instant-results-wrapper .hits').hide();
      tabs.removeClass('active');
      const i = tabs.index(selectedTab);
      selectedTab.addClass('active');
      const resultsSelector = i === 0 ? '.hits' : `.aa-dataset-${i - 1}`;
      (_container$find = container.find(resultsSelector)) === null || _container$find === void 0 ? void 0 : _container$find.show();
    }
    tabWrapper === null || tabWrapper === void 0 ? void 0 : tabWrapper.on('click', function (event) {
      const element = $(event.target);
      const selectedTab = element.hasClass('tab') ? element : element.closest('.tab');
      toggleTabs(selectedTab, wrapper, tabWrapper);
    });
    function customRender(StatsRenderingOptions, isFirstRendering) {
      if (isFirstRendering) return;
      StatsRenderingOptions.widgetParams.containerNode.html(StatsRenderingOptions.nbHits);
    }
    var customStatsWidget = algoliaBundle.instantsearch.connectors.connectStats(customRender);
    search.addWidgets([customStatsWidget({
      containerNode: $('#custom-hit-total-container')
    })]);
    function onRenderHandler() {
      positionBreakoutTiles.positionBreakoutTiles();
      $('.product-item-promo').unbind('click');
      $('.product-item-promo').click(function (e) {
        e.preventDefault();
        var content = $(this).find('.product-item-dialog');
        content.css('margin-top', -content.outerHeight() - $(this).outerHeight());
        content.toggle();
      });
      $('.ais-Hits-list, .ais-InfiniteHits-list').show();
      let isList = false;
      if (algoliaConfig.isCategoryPage) {
        isList = algoliaConfig.category.display_as_list;
      }
      if (!isList) processEqualHeights();
      facetColorSwatches();
      $(document).ready(function () {
        var _window$algolia2, _window$algolia3, _window$algolia4, _window$algolia$hitCo;
        if (!((_window$algolia2 = window.algolia) !== null && _window$algolia2 !== void 0 && (_window$algolia2 = _window$algolia2.hitCounts) !== null && _window$algolia2 !== void 0 && _window$algolia2.products) && ((_window$algolia3 = window.algolia) === null || _window$algolia3 === void 0 || (_window$algolia3 = _window$algolia3.hitCounts) === null || _window$algolia3 === void 0 ? void 0 : _window$algolia3.help) > 0) {
          toggleTabs(wrapper === null || wrapper === void 0 ? void 0 : wrapper.find('.tab.help'), wrapper, tabWrapper);
        }
        if (((_window$algolia4 = window.algolia) === null || _window$algolia4 === void 0 || (_window$algolia4 = _window$algolia4.hitCounts) === null || _window$algolia4 === void 0 ? void 0 : _window$algolia4.help) === 0 && ((_window$algolia$hitCo = window.algolia.hitCounts) === null || _window$algolia$hitCo === void 0 ? void 0 : _window$algolia$hitCo.phoneGuides) > 0) {
          toggleTabs(wrapper === null || wrapper === void 0 ? void 0 : wrapper.find('.tab.guides'), wrapper, tabWrapper);
        }
      });
      const moveAlgoliaDomElements = () => {
        let screenWidth = $(window).width();
        if (screenWidth > 1008) {
          if ($('#algolia-left-container').length) {
            $('#algolia-left-container').detach().insertAfter('#algolia-banner');
          }
          if ($('.category-pay-monthly-sim-only').length) {
            $('#reevoo-reviews').detach().insertAfter('#algolia-sort-by');
          }
        } else {
          if ($('#algolia-left-container').length) {
            if ($('.action-bar__container').length > 0) {
              $('#algolia-left-container').detach().prependTo('.action-bar__container');
            } else {
              $('#algolia-left-container').detach().prependTo('.action-bar');
            }
          }
          if ($('.category-pay-monthly-sim-only').length) {
            $('#reevoo-reviews').detach().insertBefore('#contentarea');
          }
        }
      };
      moveAlgoliaDomElements();
      $(window).resize(function () {
        moveAlgoliaDomElements();
      });
      $(".result-thumbnail.image-loading img").each(function () {
        $(this).on("load", function () {
          $(this).parent().removeClass('image-loading').find('div.spinning-loader').hide();
        });
      });
    }
    search.on('render', onRenderHandler);
    $(document).on('click', '.ais-Hits-item .product-item a.result, .ais-InfiniteHits-item .product-item a.result', function () {
      let eventName = algoliaConfig.isCategoryPage ? 'PLP: Product clicked' : 'Search page: Product clicked';
      triggerInsightsTrackClickEvent(eventName, this);
    });
    $(document).on('click', '.product-add-form.add-to-cart button.action', function () {
      triggerInsightsTrackClickEvent('PLP: Product added to cart', this, false);
    });
    $(document).on('click', '.catalogsearch-result-index .help-results .ais-InfiniteHits-item a.algoliasearch-autocomplete-hit', function () {
      triggerInsightsTrackClickEvent('Search page: Page clicked', this);
    });
    $(document).on('click', '.catalogsearch-result-index .guides-results .ais-InfiniteHits-item a.hit-name  ', function () {
      triggerInsightsTrackClickEvent('Search page: Phone guide clicked', this);
    });
    registerInsightsFilterTrackViewEvents();
    search.helper.on('change', function (helperObject) {
      registerInsightsFilterEvents(helperObject);
    });
    return search;
  });
  algolia.registerHook('beforeInstantsearchInit', function (instantsearchOptions) {
    hitTransformer();
    if (!algoliaConfig.isCategoryPage || !algoliaConfig.category.display_as_list) {
      $(window).resize(function () {
        processEqualHeights();
      });
    }
    return instantsearchOptions;
  });
  algolia.registerHook('beforeInstantsearchStart', function (search) {
    function infiniteScrollFn() {
      return {
        init: function (params) {
          window.infiniteScrollReadyToFetchMore = true;
          function scrollhandler() {
            var _params$helper, _params$helper2, _params$searchMetadat;
            if ((_params$helper = params.helper) !== null && _params$helper !== void 0 && (_params$helper = _params$helper.lastResults) !== null && _params$helper !== void 0 && _params$helper.nbPages && params.helper.getCurrentPage() >= ((_params$helper2 = params.helper) === null || _params$helper2 === void 0 ? void 0 : _params$helper2.lastResults.nbPages) - 1) {
              return;
            }
            if (params !== null && params !== void 0 && (_params$searchMetadat = params.searchMetadata) !== null && _params$searchMetadat !== void 0 && _params$searchMetadat.isSearchStalled) {
              return;
            }
            const docViewTop = $(window).scrollTop();
            const docViewBottom = docViewTop + $(window).height();
            const buttonShow = $('.ais-InfiniteHits-loadMore');
            if (buttonShow.length > 0 && !buttonShow.prop('disabled')) {
              const elemTop = buttonShow.offset().top;
              const elemBottom = elemTop + buttonShow.height();
              const needsLoading = elemBottom >= docViewTop && docViewBottom >= elemBottom;
              if (window.infiniteScrollReadyToFetchMore && needsLoading) {
                window.infiniteScrollReadyToFetchMore = false;
                params.helper.nextPage().search();
              }
            }
          }
          $(window).bind("scroll", debounce(scrollhandler, 100));
        },
        render: function (params) {
          var _params$searchMetadat2;
          if (params !== null && params !== void 0 && (_params$searchMetadat2 = params.searchMetadata) !== null && _params$searchMetadat2 !== void 0 && _params$searchMetadat2.isSearchStalled) {
            return;
          }
          this.removeSkeletonLoader();
          window.infiniteScrollReadyToFetchMore = true;
        },
        removeSkeletonLoader: function () {
          $('.skeleton-loader--container').hide();
        }
      };
    }
    search.addWidget(infiniteScrollFn());
    return search;
  });
  algolia.registerHook('beforeInstantsearchInit', function (instantsearchOptions, algoliaBundle) {
    const originalSearch = instantsearchOptions.searchFunction;
    instantsearchOptions.searchFunction = function (helper) {
      if (algoliaConfig.isCategoryPage) {
        var _algoliaConfig;
        const facets = (_algoliaConfig = algoliaConfig) === null || _algoliaConfig === void 0 ? void 0 : _algoliaConfig.category.custom_facets;
        if (facets !== null && facets !== void 0 && facets.length) {
          const originalDisjunctiveFacets = helper.state.disjunctiveFacets;
          helper.state.disjunctiveFacets = [];
          for (const facet of facets) {
            if (originalDisjunctiveFacets.indexOf(facet) !== -1) {
              helper.state.disjunctiveFacets.push(facet);
              continue;
            }
            helper.state.facets.push(facet);
          }
        }
      }
      if (!helper.state.query) {
        originalSearch(helper);
        return;
      }
      if (!window.algolia.hitCounts) {
        window.algolia.hitCounts = {};
      }
      document.querySelector('.help-results').style.display = 'none';
      const pagesSearchFunction = function (pagesHelper) {
        var query = helper.state.query;
        if (query && !pagesHelper.state.query) {
          pagesHelper.setQuery(query);
        }
        pagesHelper.search();
      };
      const pagesSearch = algoliaBundle.instantsearch({
        searchClient: instantsearchOptions.searchClient,
        indexName: helper.state.index.replace('products', 'pages'),
        searchFunction: pagesSearchFunction,
        searchParameters: {
          hitsPerPage: algoliaConfig.hitsPerPage
        }
      });
      pagesSearch.addWidgets([algoliaBundle.instantsearch.widgets.configure({
        clickAnalytics: true
      }), algoliaBundle.instantsearch.widgets.infiniteHits({
        transformItems: items => {
          var _pagesSearch$helper;
          if (pagesSearch !== null && pagesSearch !== void 0 && (_pagesSearch$helper = pagesSearch.helper) !== null && _pagesSearch$helper !== void 0 && _pagesSearch$helper.lastResults) {
            window.algolia.hitCounts.help = pagesSearch.helper.lastResults.nbHits;
          } else {
            window.algolia.hitCounts.help = 0;
          }
          setTimeout(function () {
            if (!$('.phone-guides-next').length) {
              return;
            }
            $('.pages-next').off('click');
            $('.pages-next').on('click', () => {
              pagesSearch.refresh();
            });
          }, 350);
          return items.map(item => {
            var _item$_snippetResult, _item$breadcrumbs;
            return _objectSpread(_objectSpread({}, item), {}, {
              __indexName: pagesSearch === null || pagesSearch === void 0 ? void 0 : pagesSearch.indexName,
              content: item !== null && item !== void 0 && (_item$_snippetResult = item._snippetResult) !== null && _item$_snippetResult !== void 0 && (_item$_snippetResult = _item$_snippetResult.content) !== null && _item$_snippetResult !== void 0 && _item$_snippetResult.value ? snippetResultContent(item) : item.content,
              breadcrumb: item === null || item === void 0 || (_item$breadcrumbs = item.breadcrumbs) === null || _item$breadcrumbs === void 0 ? void 0 : _item$breadcrumbs.split('|').map(s => {
                return {
                  name: s
                };
              })
            });
          });
        },
        container: document.querySelector('.help-results'),
        cssClasses: {
          loadPrevious: ['action', 'primary', 'pages-prev'],
          loadMore: ['action', 'primary', 'pages-next']
        },
        templates: {
          item: `<div class="aa-suggestion" role="option">
                            <a class="algoliasearch-autocomplete-hit" href="{{url}}" style="white-space: normal;"
                             data-objectId="{{objectID}}"
                             data-position="{{__position}}"
                             data-index="{{__indexName}}"
                             data-queryid="{{__queryID}}"
                            >
                                <div class="breadcrumb">
                                {{#breadcrumb}}
                                    <span class="path">{{name}}</span>
                                {{/breadcrumb}}
                                </div>
                                <div class="hit-name">
                                    {{name}}
                                </div>
                                <div class="details">{{{content}}}</div>
                                <div class="algolia-clearfix"></div>
                            </a>
                        </div>`
        }
      }), algoliaBundle.instantsearch.widgets.stats({
        container: '#tab-pages-count',
        templates: {
          text: `
                          {{^areHitsSorted}}
                            {{#hasNoResults}}(0){{/hasNoResults}}
                            {{#hasOneResult}}({{#helpers.formatNumber}}{{nbHits}}{{/helpers.formatNumber}}){{/hasOneResult}}
                            {{#hasManyResults}}({{#helpers.formatNumber}}{{nbHits}}{{/helpers.formatNumber}}){{/hasManyResults}}
                          {{/areHitsSorted}}
                        `
        }
      }), algoliaBundle.instantsearch.widgets.configure({
        analyticsTags: analyticsTags.getTags('instantsearch')
      })]);
      pagesSearch.start();
      document.querySelector('.guides-results').style.display = 'none';
      const lucidSearchFunction = function (lucidHelper) {
        var query = helper.state.query;
        if (query && !lucidHelper.state.query) {
          lucidHelper.setQuery(query);
        }
        lucidHelper.search();
      };
      const lucidSearch = algoliaBundle.instantsearch({
        searchClient: instantsearchOptions.searchClient,
        indexName: `${algoliaConfig.indexName}_lucid`,
        searchFunction: lucidSearchFunction,
        searchParameters: {
          hitsPerPage: algoliaConfig.hitsPerPage
        }
      });
      lucidSearch.addWidgets([algoliaBundle.instantsearch.widgets.configure({
        clickAnalytics: true
      }), algoliaBundle.instantsearch.widgets.infiniteHits({
        transformItems: items => {
          var _lucidSearch$helper;
          window.algolia.hitCounts.phoneGuides = lucidSearch !== null && lucidSearch !== void 0 && (_lucidSearch$helper = lucidSearch.helper) !== null && _lucidSearch$helper !== void 0 && _lucidSearch$helper.lastResults ? lucidSearch.helper.lastResults.nbHits : 0;
          setTimeout(function () {
            if (!$('.phone-guides-next').length) {
              return;
            }
            $('.phone-guides-next').off('click');
            $('.phone-guides-next').on('click', () => {
              lucidSearch.refresh();
            });
          }, 350);
          return items.map(item => _objectSpread(_objectSpread({}, item), {}, {
            __indexName: lucidSearch === null || lucidSearch === void 0 ? void 0 : lucidSearch.indexName,
            breadcrumb: Array.isArray(item.breadcrumb) ? item.breadcrumb.map(s => {
              return {
                name: s
              };
            }) : item.breadcrumb.split('|').map(s => {
              return {
                name: s
              };
            })
          }));
        },
        container: document.querySelector('.guides-results'),
        cssClasses: {
          loadPrevious: ['action', 'primary', 'phone-guides-prev'],
          loadMore: ['action', 'primary', 'phone-guides-next']
        },
        templates: {
          item: `<div>
                            <div class="breadcrumb">
                                {{#breadcrumb}}
                                    <span class="path">{{name}}</span>
                                {{/breadcrumb}}
                            </div>
                            <a href="{{link}}" class="hit-name"
                             data-objectId="{{objectID}}"
                             data-position="{{__position}}"
                             data-index="{{__indexName}}"
                             data-queryid="{{__queryID}}"
                            >{{meta}}</a>
                        </div>`
        }
      }), algoliaBundle.instantsearch.widgets.stats({
        container: '#tab-lucid-count',
        templates: {
          text: `
                          {{^areHitsSorted}}
                            {{#hasNoResults}}(0){{/hasNoResults}}
                            {{#hasOneResult}}({{#helpers.formatNumber}}{{nbHits}}{{/helpers.formatNumber}}){{/hasOneResult}}
                            {{#hasManyResults}}({{#helpers.formatNumber}}{{nbHits}}{{/helpers.formatNumber}}){{/hasManyResults}}
                          {{/areHitsSorted}}
                        `
        }
      }), algoliaBundle.instantsearch.widgets.configure({
        analyticsTags: analyticsTags.getTags('instantsearch')
      })]);
      lucidSearch.start();
      originalSearch(helper);
    };
    return instantsearchOptions;
  });
  function autocompleResultsFixPosition() {
    const pageHeader = $('.page-header .header.content');
    const headerHeight = pageHeader.outerHeight();
    const autocompleteResultContainer = $('.aa-Panel');
    if ($(document).width() > 1008 && autocompleteResultContainer.length) {
      autocompleteResultContainer.css({
        'top': headerHeight
      });
    }
  }
  algolia.registerHook('afterAutocompleteOptions', function (options) {
    let tabSelectTimer = null;
    return _objectSpread({
      panelContainer: '#algolia-autocomplete-container',
      panelPlacement: 'full-width',
      classNames: {
        panel: 'aa-dropdown-menu'
      },
      onStateChange: function (_ref) {
        let prevState = _ref.prevState,
          state = _ref.state;
        if (state.collections.length === 0) {
          return;
        }
        const prevStateHits = prevState.collections.map(_ref2 => {
          var _items$;
          let items = _ref2.items;
          return (_items$ = items[0]) === null || _items$ === void 0 ? void 0 : _items$.nbHits;
        });
        const stateHits = state.collections.map(_ref3 => {
          var _items$2;
          let items = _ref3.items;
          return (_items$2 = items[0]) === null || _items$2 === void 0 ? void 0 : _items$2.nbHits;
        });
        if (_.difference(stateHits, prevStateHits).length > 0) {
          if (tabSelectTimer) {
            clearTimeout(tabSelectTimer);
          }
          tabSelectTimer = setTimeout(function () {
            const tabs = $('.autocomplete-wrapper .tab');
            const tabToSelect = state.collections.findIndex(_ref4 => {
              var _items$3;
              let items = _ref4.items;
              return ((_items$3 = items[0]) === null || _items$3 === void 0 ? void 0 : _items$3.nbHits) > 0;
            });
            if (tabs.length && tabToSelect > -1) {
              handleAutocompleteTabClick($(tabs[tabToSelect]));
            }
          }, 100);
        }
      },
      render: function (_ref5, root) {
        let render = _ref5.render,
          html = _ref5.html,
          sections = _ref5.sections,
          state = _ref5.state;
        render(autocompleteWrapper.getHtml({
          html,
          sections,
          state
        }), root);
        const productContainerSelector = '.aa-Source[data-autocomplete-source-id="products"]';
        if ($(productContainerSelector).length) {
          equalHeights(productContainerSelector, '.result-pre-content');
          equalHeights(productContainerSelector, '.ratings');
          equalHeights(productContainerSelector, '.result-lower-content');
          equalHeights(productContainerSelector, '.result-thumbnail');
        }
        autocompleResultsFixPosition();
      }
    }, options);
  });
  function handleAutocompleteTabClick(element) {
    const parent = element.parent();
    const selectedTab = element.hasClass('tab') ? element : parent.hasClass('tab') ? parent : null;
    if (!selectedTab) {
      return;
    }
    const container = selectedTab.closest('.autocomplete-wrapper');
    selectedTab.parent().find('.tab').removeClass('active');
    selectedTab.addClass('active');
    $('.aa-Source', container).hide();
    container.find(`[data-autocomplete-source-id="${selectedTab.data('sourceId')}"]`).show();
  }
  algolia.registerHook('afterAutocompleteStart', function (autocompleteInstance) {
    $('#algolia-autocomplete-container, .algolia-search-actions').on('click', function (event) {
      const element = $(event.target);
      const parent = element.parent();
      const closeClass = 'algolia-close';
      if (element.hasClass(closeClass) || parent.hasClass(closeClass)) {
        return autocompleteInstance.setIsOpen(false);
      }
      handleAutocompleteTabClick(element);
    });
    $(document).on('submit', function (event) {
      var _target$find;
      const target = $(event.target);
      if (!target.hasClass('aa-Form')) {
        return;
      }
      event.preventDefault();
      const query = ((_target$find = target.find('input.aa-Input')) === null || _target$find === void 0 ? void 0 : _target$find.val()) || '__empty__';
      const url = new URL(algoliaConfig.resultPageUrl);
      url.searchParams.set('q', query);
      window.location.href = url.toString();
    });
    $(window).bind('resize', debounce(autocompleResultsFixPosition, 25));
  });
  algolia.registerHook('afterAutocompleteSources', function (sources) {
    sources.push({
      sourceId: 'lucid',
      options: {
        hitsPerPage: 20,
        analyticsTags: analyticsTags.getTags('autocomplete'),
        clickAnalytics: true,
        distinct: true
      },
      indexName: `${algoliaConfig.indexName}_lucid`,
      transformResponse: _ref6 => {
        let results = _ref6.results,
          hits = _ref6.hits;
        return hits.map(res => res.map((hit, i) => _objectSpread(_objectSpread({}, hit), {}, {
          nbHits: results[0].nbHits,
          position: i + 1
        })));
      },
      templates: {
        noResults(_ref7) {
          let html = _ref7.html;
          return lucidHtml.getNoResultHtml({
            html
          });
        },
        header(_ref8) {
          let html = _ref8.html,
            items = _ref8.items;
          return lucidHtml.getHeaderHtml({
            html,
            items
          });
        },
        item(_ref9) {
          let item = _ref9.item,
            components = _ref9.components,
            html = _ref9.html;
          return lucidHtml.getItemHtml({
            item,
            components,
            html
          });
        },
        footer(_ref10) {
          let html = _ref10.html,
            items = _ref10.items;
          return lucidHtml.getFooterHtml({
            html,
            items
          });
        }
      }
    });
    sources.map(source => {
      if (source.sourceId === 'pages') {
        const transformResponse = source.transformResponse;
        source.transformResponse = params => {
          const results = params.results;
          const hits = transformResponse(params);
          return hits.map(res => res.map(hit => _objectSpread(_objectSpread({}, hit), {}, {
            nbHits: results[0].nbHits
          })));
        };
      } else if (source.sourceId === 'products') {
        const transformResponse = source.transformResponse;
        source.transformResponse = params => {
          const hits = transformResponse(params);
          return hits.map(res => res.map(hit => _objectSpread({}, autocompleteTransformHit(hit))));
        };
      }
      source.options.analyticsTags = analyticsTags.getTags('autocomplete');
      return source;
    });
    return sources;
  });
  const registerInsightsPDPEvents = () => {
    if ($('body').hasClass('catalog-product-view')) {
      const algoliaInsights = window.algoliaInsights;
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const paramsQueryID = urlParams.get('queryID') || null;
      const paramsIndexName = urlParams.get('indexName') || algoliaInsights.defaultIndexName;
      const isSearchPage = urlParams.get('isSearchPage') || null;
      ;
      let objectId = $('#product_addtocart_form').find('input[name="product"]').val();
      if (objectId) {
        let eventDataView = algoliaInsights.buildEventData('Product Viewed', objectId, paramsIndexName, null, paramsQueryID);
        algoliaInsights.trackView(eventDataView);
        $(document).on('ajax:addToCart', function (e, data) {
          if ($.inArray(objectId, data === null || data === void 0 ? void 0 : data.productIds) === -1) {
            return;
          }
          let eventName = !isSearchPage ? 'PDP: Product added to cart' : 'PDP: Product added to cart after search';
          var eventData = algoliaInsights.buildEventData(eventName, objectId, paramsIndexName, null, paramsQueryID);
          algoliaInsights.trackConversion(eventData);
        });
      }
    }
  };
  const registerInsightsFilterTrackViewEvents = () => {
    const algoliaConfig = window.algoliaConfig;
    const algoliaInsights = window.algoliaInsights;
    const facets = algoliaInsights.config.facets;
    if (!algoliaConfig.isCategoryPage || facets.length < 1) {
      return;
    }
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let appliedFilters = [];
    if (urlParams.size > 0) {
      facets.forEach(function (facet) {
        let attribute = facet === null || facet === void 0 ? void 0 : facet.attribute;
        if (urlParams.has(attribute)) {
          appliedFilters.push((facet === null || facet === void 0 ? void 0 : facet.attribute) + ':' + urlParams.get(attribute));
        }
      });
      if (appliedFilters.length > 0) {
        let eventDataView = {
          eventName: 'PLP: Filter Viewed',
          objectIDs: appliedFilters,
          index: algoliaInsights.defaultIndexName
        };
        algoliaInsights.trackView(eventDataView);
      }
    }
  };
  const registerInsightsFilterEvents = helperObject => {
    const _window = window,
      algoliaConfig = _window.algoliaConfig,
      algoliaInsights = _window.algoliaInsights,
      algoliaAnalytics = _window.algoliaAnalytics;
    const facets = algoliaInsights.config.facets;
    if (!algoliaConfig.isCategoryPage || facets.length < 1) {
      return;
    }
    const refinementsToTrack = [];
    const newState = helperObject.state;
    const oldState = helperObject.results._state;
    const newValueObject = _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, newState.disjunctiveFacetsRefinements), newState.facetsRefinements), newState.hierarchicalFacetsRefinements), newState.numericRefinements);
    const oldValueObject = _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, oldState.disjunctiveFacetsRefinements), oldState.facetsRefinements), oldState.hierarchicalFacetsRefinements), oldState.numericRefinements);
    const updateRefinementsToTrack = (attribute, difference) => {
      if (difference) {
        refinementsToTrack.push(`${attribute}:${difference}`);
      }
    };
    facets.forEach(_ref11 => {
      let attribute = _ref11.attribute;
      const newAttributeValue = newValueObject[attribute];
      const oldAttributeValue = oldValueObject[attribute];
      if (JSON.stringify(newAttributeValue) === JSON.stringify(oldAttributeValue)) {
        return;
      }
      if (Array.isArray(newAttributeValue)) {
        const difference = newAttributeValue.filter(x => !oldAttributeValue.includes(x)).join(', ');
        return updateRefinementsToTrack(attribute, difference);
      }
      if (newAttributeValue instanceof Object) {
        var _newAttributeValue$, _newAttributeValue$2;
        const objectKeys = Object.keys(newAttributeValue);
        if (objectKeys.includes('=')) {
          return updateRefinementsToTrack(attribute, newAttributeValue['='][0]);
        }
        const difference = "%1:%2".replace('%1', ((_newAttributeValue$ = newAttributeValue['>=']) === null || _newAttributeValue$ === void 0 ? void 0 : _newAttributeValue$[0]) || '').replace('%2', ((_newAttributeValue$2 = newAttributeValue['<=']) === null || _newAttributeValue$2 === void 0 ? void 0 : _newAttributeValue$2[0]) || '');
        if (difference.length < 2) {
          return;
        }
        return updateRefinementsToTrack(attribute, difference);
      }
      return updateRefinementsToTrack(attribute, newAttributeValue);
    });
    if (refinementsToTrack.length > 0) {
      const eventData = {
        eventName: 'PLP: Filter Clicked',
        filters: refinementsToTrack,
        index: algoliaInsights.defaultIndexName
      };
      algoliaAnalytics.clickedFilters(eventData);
    }
  };
  function facetColorSwatches() {
    $('.is-widget-container-color ul li').each(function (i) {
      const colorName = $(this).find('.name').text();
      $(this).find('.swatch').css('background-color', swatchData[colorName]);
    });
  }
  function processEqualHeights() {
    if (isMobile() === false) {
      if ($('.products.product-items').length) {
        equalHeights('.products.product-items', '.product-item-name');
        equalHeights('.products.product-items', '.result-lower-content');
        equalHeights('.products.product-items', '.result-thumbnail');
      }
      if ($('.ais-Hits-list').length) {
        equalHeights('.ais-Hits-list', '.result-pre-content');
        equalHeights('.ais-Hits-list', '.ratings');
        equalHeights('.ais-Hits-list', '.result-lower-content');
        equalHeights('.ais-Hits-list', '.result-thumbnail');
      } else {
        equalHeights('.ais-InfiniteHits-list', '.result-pre-content');
        equalHeights('.ais-InfiniteHits-list', '.ratings');
        equalHeights('.ais-InfiniteHits-list', '.result-lower-content');
        equalHeights('.ais-InfiniteHits-list', '.result-thumbnail');
      }
    }
  }
  function equalHeights(items, item) {
    $(items + ' ' + item).height('unset');
    $(items).each(function () {
      var highestBox = 0;
      $(this).find(item).each(function () {
        if ($(this).height() > highestBox) {
          highestBox = $(this).height();
        }
      });
      $(this).find(item).height(highestBox);
    });
  }
  window.routing.router = algoliaBundle.instantsearch.routers.history({
    parseURL: function (qsObject) {
      var location = qsObject.location,
        qsModule = qsObject.qsModule;
      const queryString = location.hash ? location.hash : location.search;
      return qsModule.parse(queryString.slice(1));
    },
    createURL: function (qsObject) {
      var qsModule = qsObject.qsModule,
        routeState = qsObject.routeState,
        location = qsObject.location;
      const protocol = location.protocol,
        hostname = location.hostname,
        port = location.port ? location.port : '',
        pathname = location.pathname,
        hash = location.hash;
      if (itemReplace) {
        routeState.item_replace = itemReplace;
      }
      for (const _ref12 of Object.entries(parametersObject)) {
        var _ref13 = _slicedToArray(_ref12, 2);
        const key = _ref13[0];
        const value = _ref13[1];
        if (value) {
          routeState[key] = value;
        }
      }
      const queryString = qsModule.stringify(routeState);
      const portWithPrefix = port === '' ? '' : ':' + port;
      if (!routeState || Object.keys(routeState).length === 0) {
        return protocol + '//' + hostname + portWithPrefix + pathname;
      } else {
        if (queryString && queryString != 'q=__empty__') {
          return protocol + '//' + hostname + portWithPrefix + pathname + '?' + queryString;
        } else {
          return protocol + '//' + hostname + portWithPrefix + pathname;
        }
      }
    }
  });
  window.algolia.helpSearch = {};
  $(function () {
    if ($('.cmp-helpsupport-structure').length === 0) {
      return;
    }
    const searchBoxId = 'algolia-help-searchbox';
    const parentElement = $('.cms-help .cmp-helpsupport-structure .container').first();
    const searchTitle = 'What can we help you with?';
    const inputPlaceholer = '*more data, upgrade, charges on bill*';
    const tabTextHelp = 'Help';
    const tabTextLucid = 'Phone guides';
    const helpMainPageClass = 'cms-help';
    if ($(`#${searchBoxId}`).length === 0) {
      const searchboxHtml = `

            <div id="${searchBoxId}">
            <h2 class="algolia-help-searchbox-title">${searchTitle}</h2>
                <span class="${searchBoxId}-autocomplete" style="position: relative; display: inline-block; direction: ltr;">
                <form class="ais-SearchBox-form" action="${algoliaConfig.resultPageUrl}" method="get">
                    <input
                        id="${searchBoxId}-search"
                        type="search"
                        name="q"
                        class="input-text ${searchBoxId}-input aa-input"
                        autocomplete="off"
                        spellcheck="false"
                        autocorrect="off"
                        autocapitalize="off"
                        placeholder="${inputPlaceholer}"
                        role="combobox"
                        aria-autocomplete="both"
                        aria-expanded="false"
                        dir="auto"
                        style="position: relative; vertical-align: top;"
                    />
                     <button class="ais-SearchBox-submit algolia-help-submit" type="submit" title="Search">Search</button>
                    <span class="clear-btn">Clear</span>
                    </form>
                </span>
                <div class="algoliasearch-hits-container">
                    <div class="algoliasearch-tabs">
                        <span class="algoliasearch-tab help">${tabTextHelp} <span></span></span>
                        <span class="algoliasearch-tab lucid">${tabTextLucid} <span></span></span>
                    </div>
                    <div class="algoliasearch-hits">
                        <div class="hit help"></div>
                        <div class="hit lucid"></div>
                    </div>
                </div>


            </div>
            `;
      parentElement.prepend(searchboxHtml);
    }
    let isStarted = false;
    const searchBox = $(`#${searchBoxId} input`);
    const helpTab = $(`#${searchBoxId} .hit.help`);
    const lucidTab = $(`#${searchBoxId} .hit.lucid`);
    const client = algoliaBundle.algoliasearch(algoliaConfig.applicationId, algoliaConfig.apiKey);
    const pagesSearch = algoliaBundle.instantsearch({
      indexName: `${algoliaConfig.indexName}_pages`,
      searchClient: client
    });
    if (searchBox.length > 0) {
      pagesSearch.addWidgets([algoliaBundle.instantsearch.widgets.searchBox({
        container: `#${searchBoxId} input`
      }), algoliaBundle.instantsearch.widgets.hits({
        container: `#${searchBoxId} .hit.help`,
        transformItems: items => {
          let countItems = items.length;
          helpSearchTabs('help', countItems);
          return items.map(item => {
            var _item$_snippetResult2;
            return _objectSpread(_objectSpread({}, item), {}, {
              __breadcrumbs: item !== null && item !== void 0 && item.breadcrumbs ? item.breadcrumbs.split('|').map(s => {
                return {
                  name: s
                };
              }) : '',
              content: item !== null && item !== void 0 && (_item$_snippetResult2 = item._snippetResult) !== null && _item$_snippetResult2 !== void 0 && (_item$_snippetResult2 = _item$_snippetResult2.content) !== null && _item$_snippetResult2 !== void 0 && _item$_snippetResult2.value ? snippetResultContent(item) : item.content
            });
          });
        },
        templates: {
          item: `
                    <div class="aa-breadcrumb">
                        {{#__breadcrumbs}}
                            <span class="path">{{name}}</span>
                        {{/__breadcrumbs}}
                    </div>
                    <h5><a href="{{url}}">{{name}}</a></h5>
                    {{#content}}
                    <div class="content">
                        {{{content}}}
                    </div>
                    {{/content}}
                    `
        }
      })]);
    }
    const lucidSearch = algoliaBundle.instantsearch({
      indexName: `${algoliaConfig.indexName}_lucid`,
      searchClient: client
    });
    if (searchBox.length > 0) {
      lucidSearch.addWidgets([algoliaBundle.instantsearch.widgets.hits({
        container: `#${searchBoxId} .hit.lucid`,
        transformItems: items => {
          let countItems = items.length;
          helpSearchTabs('lucid', countItems);
          return items.map(item => {
            var _item$_snippetResult3;
            return _objectSpread(_objectSpread({}, item), {}, {
              __breadcrumb: item !== null && item !== void 0 && item.breadcrumb ? item.breadcrumb.split('|').map(s => {
                return {
                  name: s
                };
              }) : '',
              content: item !== null && item !== void 0 && (_item$_snippetResult3 = item._snippetResult) !== null && _item$_snippetResult3 !== void 0 && (_item$_snippetResult3 = _item$_snippetResult3.content) !== null && _item$_snippetResult3 !== void 0 && _item$_snippetResult3.value ? snippetResultContent(item) : item.content
            });
          });
        },
        templates: {
          item: `
                        <div class="aa-breadcrumb">
                            {{#__breadcrumb}}
                                <span class="path">{{name}}</span>
                            {{/__breadcrumb}}
                        </div>
                        <h5><a href="{{link}}">{{meta}}</a></h5>
                        {{#content}}
                        <div class="content">
                            {{{content}}}
                        </div>
                        {{/content}}
                    `
        }
      })]);
    }
    let timeoutId = 0;
    searchBox.on('keyup', function () {
      if ($(this).val() === '') {
        $(`#${searchBoxId} .algoliasearch-hits-container`).hide();
        $(`#${searchBoxId} .algolia-help-searchbox-autocomplete`).removeClass('result-active');
        $('#maincontent').removeClass('help-searchbox-active');
        window.algolia.helpSearch.tabFlag = undefined;
        return;
      }
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      let theValue = $(this).val();
      timeoutId = setTimeout(function () {
        var _pagesSearch$helper2;
        if (!isStarted) {
          pagesSearch.start();
          lucidSearch.start();
          isStarted = true;
        }
        pagesSearch.helper.setQuery(theValue).search();
        let hitCount = (pagesSearch === null || pagesSearch === void 0 || (_pagesSearch$helper2 = pagesSearch.helper) === null || _pagesSearch$helper2 === void 0 || (_pagesSearch$helper2 = _pagesSearch$helper2.lastResults) === null || _pagesSearch$helper2 === void 0 ? void 0 : _pagesSearch$helper2.nbHits) || 0;
        lucidSearch.helper.setQuery(theValue).search();
        $(`#${searchBoxId} .algoliasearch-hits-container`).show();
        $(`#${searchBoxId} .algolia-help-searchbox-autocomplete`).addClass('result-active');
        $('#maincontent').addClass('help-searchbox-active');
      }, 500);
    });
    $('.algoliasearch-tab.help').on('click', function () {
      var _window2;
      if (((_window2 = window) === null || _window2 === void 0 || (_window2 = _window2.algolia) === null || _window2 === void 0 || (_window2 = _window2.helpSearch) === null || _window2 === void 0 ? void 0 : _window2.tabFlag) !== 'help') {
        window.algolia.helpSearch.tabFlag = 'help';
        toggleTabs('help');
      }
    });
    $('.algoliasearch-tab.lucid').on('click', function () {
      var _window3;
      if (((_window3 = window) === null || _window3 === void 0 || (_window3 = _window3.algolia) === null || _window3 === void 0 || (_window3 = _window3.helpSearch) === null || _window3 === void 0 ? void 0 : _window3.tabFlag) !== 'lucid') {
        window.algolia.helpSearch.tabFlag = 'lucid';
        toggleTabs('lucid');
      }
    });
    $('.algolia-help-searchbox-autocomplete .clear-btn').on('click', function () {
      $(`#${searchBoxId} .algoliasearch-hits-container`).hide();
      $(`#${searchBoxId} .algolia-help-searchbox-autocomplete`).removeClass('result-active');
      $('#maincontent').removeClass('help-searchbox-active');
      $('#algolia-help-searchbox-search').val('');
      window.algolia.helpSearch.tabFlag = undefined;
    });
  });
  const helpSearchTabs = (tab, count) => {
    if (tab === 'help') {
      $('.algoliasearch-tab.help > span').text('(' + count + ')');
      window.algolia.helpSearch.help = count;
    }
    if (tab === 'lucid') {
      $('.algoliasearch-tab.lucid > span').text('(' + count + ')');
      window.algolia.helpSearch.lucid = count;
    }
    if (window.algolia.helpSearch.help > 0 && typeof window.algolia.helpSearch.tabFlag === "undefined") {
      toggleTabs('help');
    }
    if (window.algolia.helpSearch.help === 0 && window.algolia.helpSearch.lucid > 0 && typeof window.algolia.helpSearch.tabFlag === "undefined") {
      toggleTabs('lucid');
    }
  };
  const toggleTabs = tab => {
    const helpTab = $('#algolia-help-searchbox .hit.help');
    const helpTabLink = $('.algoliasearch-tab.help');
    const lucidTab = $('#algolia-help-searchbox .hit.lucid');
    const lucidTabLink = $('.algoliasearch-tab.lucid');
    if (tab === 'help') {
      helpTab.show();
      lucidTab.hide();
      helpTabLink.addClass('active');
      lucidTabLink.removeClass('active');
    }
    if (tab === 'lucid') {
      helpTab.hide();
      lucidTab.show();
      helpTabLink.removeClass('active');
      lucidTabLink.addClass('active');
    }
  };
});

